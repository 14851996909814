import { initializeApp } from 'firebase/app'
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import {
    browserLocalPersistence,
    browserSessionPersistence,
    indexedDBLocalPersistence,
    initializeAuth,
} from 'firebase/auth'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: 'bloom-cafe.firebaseapp.com',
    projectId: 'bloom-cafe',
    storageBucket: 'bloom-cafe.firebasestorage.app',
    messagingSenderId: '415236207377',
    appId: '1:415236207377:web:4eb78be1e94248dabd52b0',
    measurementId: 'G-LEKVLLY1HG',
}

export const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)

export const auth = initializeAuth(app, {
    persistence: [
        indexedDBLocalPersistence,
        browserLocalPersistence,
        browserSessionPersistence,
    ],
    popupRedirectResolver: undefined,
})

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
if (recaptchaSiteKey !== undefined) {
    if (process.env.NODE_ENV === 'development') {
        // @ts-ignore
        // eslint-disable-next-line no-restricted-globals
        self.FIREBASE_APPCHECK_DEBUG_TOKEN =
            process.env.REACT_APP_APP_CHECK_DEBUG_TOKEN
    }

    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(recaptchaSiteKey),
        isTokenAutoRefreshEnabled: true,
    })
}

enableIndexedDbPersistence(db).catch(err => {
    if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
})
